import React from "react";
import { Avatar, Dropdown, Space, Layout, Typography } from "antd";
import { Link } from "react-router-dom";

import logo from "../assets/images/logo-no-background.png";

const { Header } = Layout;
const { Text } = Typography;

const items = [
  // {
  //   key: "1",
  //   label:
  //       <Link to="/profile">
  //       <span>Thông tin cá nhân</span>
  //   </Link>,
  // },
    {
    key: "2",
    label: <Link to="/logout">Đăng Xuất</Link>,
  },
];

const infoUsers = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

export default function HeaderCustom() {
  return (
    <Header className="header">
      <div className="logo">
        <img src={logo} alt="" />
        {/* <span className="spanVip">VIP</span> */}
      </div>
      <Dropdown
        menu={{
          items,
        }}
      >
        <Space className="ant-space-item-header">
          <Avatar
            style={{
              backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              verticalAlign: "middle",
            }}
            size={30}
          >
            {infoUsers?.fullName?.charAt(0)}
          </Avatar>
          <div className="group-info-sidebar">
            <Text className="username-sidebar" strong>
              {infoUsers?.fullName}
            </Text>
            <Text className="rolename-sidebar">{infoUsers?.roleName}</Text>
          </div>
        </Space>
      </Dropdown>
    </Header>
  );
}
