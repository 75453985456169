// Desc: Custom hook to get role of user
import { ADMIN_ROLE } from "../common/const";

export const useRole = () => {
  const infoUsers = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  let role = infoUsers?.listRole;
  role && (role = role.map((item) => item.toString().toLowerCase()))
  const checkRole = (_role) => {
    if (_role === "user") {
      return true;
    }

    if (Array.isArray(role)) {
      if (role.includes(ADMIN_ROLE.toLowerCase())) {
        return true;
      }
      if (_role === null) {
        return true;
      }
      return !!role.includes(_role.toString().toLowerCase());
    }
    if (role === ADMIN_ROLE || _role === role) {
      return true;
    }
    return _role === null;
  };
  return {
    role,
    checkRole,
  };
};
