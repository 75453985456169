import { createContext, useEffect, useMemo, useState } from "react";
import fingerPrintJs from "@fingerprintjs/fingerprintjs";
import {Spin} from "antd";
// import axios from "axios";

export const FingerPrintContext = createContext({
  fingerPrint: null,
  userData: null,
});

// const getIpUrl = process.env.REACT_APP_API_GET_IP_URL;
export const FingerPrintProvider = ({ children }) => {
  const [fingerPrint, setFingerPrint] = useState(null);
  const [waitToCheck, setWaitToCheck] = useState(true);
  // const [userData, setUserData] = useState(null);

  useEffect(() => {
    const getFingerPrint = async () => {
      const fp = await fingerPrintJs.load();
      const resultPromise = fp.get();
      // const userDataPromise = axios.get(getIpUrl);
      try {
        const [result] = await Promise.all([
          resultPromise,
          // userDataPromise,
        ]);
        // setUserData(userDataRes.data);
        setFingerPrint(result.visitorId);
      } catch (error) {
        console.error("Error occurred:", error);
        // handle error, for instance setting userData to null
        // setUserData(null);
      }
      finally {
        setWaitToCheck(false);
      }
    };
    getFingerPrint();
  }, []);

  const value = useMemo(() => ({ fingerPrint }), [fingerPrint]);

  if(waitToCheck) return <Spin />

  return <FingerPrintContext.Provider value={value}>{children}</FingerPrintContext.Provider>;
};
