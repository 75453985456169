import { useRole } from "../hooks/useRole";
import { Navigate } from "react-router-dom";

export const RoleProtected = ({ children, role, ...rest }) => {
  const { checkRole } = useRole();
  if (checkRole(role)) {
    return children;
  }
  return <Navigate to="/404" replace={true} />;
};
