import React, {useCallback, useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "../pages/auth/login";
import Logout from "../pages/auth/logout";
import Page404 from "../pages/error/404";

import AuthProtectedLayout from "../layouts/";
import { Spin } from "antd";
import { parseJwt } from "../utils/parser-jwt";

const AuthProtected = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useState(
    localStorage.getItem("token") ? true : false
  );

  const toCamelCase = useCallback((obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => toCamelCase(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
          (result, key) => ({
            ...result,
            [key.charAt(0).toLowerCase() + key.slice(1)]: toCamelCase(obj[key]),
          }),
          {}
      );
    }
    return obj;
  }, [])

  useEffect(() => {
    const verify = async () => {
      try {
        setIsLoading(true);
        const decoded = parseJwt(localStorage.getItem("token"));
        const userData = JSON.parse(decoded?.LogedInUser || "{}");
        //     transform data
        let transformedData = toCamelCase(userData);
        //   set local storage
        localStorage.setItem("user", JSON.stringify(transformedData));
      } catch (err) {
        console.log("error");
      } finally {
        setIsLoading(false);
      }
    };
    if (auth) {
      verify();
    }
  }, [auth, toCamelCase]);
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Spin />
      </div>
    );
  }
  if (!auth) {
    return <Navigate to="/login" />;
  }
  return children;
};
const Index = () => {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route
            path="/*"
            element={
              <AuthProtected>
                <AuthProtectedLayout />
              </AuthProtected>
            }
          />
          <Route element={<Login />} path="/login" />;
          <Route element={<Logout />} path="/logout" />
          <Route element={<Page404 />} path="/404" />
        </Routes>
      </Router>
    </React.Fragment>
  );
};

export default Index;
