import React, { useEffect } from "react";
import Route from "./routes";
import { FingerPrintProvider } from "./hooks/fingerPrintContext";

import "./assets/styles/animation.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  return (
    <>
      <FingerPrintProvider>
        <Route />
        <a href="https://okvip678.com/" style={{ display: "none" }}>
          <span>Hidden link</span>
        </a>
      </FingerPrintProvider>
    </>
  );
}

export default App;
